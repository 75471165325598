import React, { useState } from "react";
import emailjs from "emailjs-com";
import swal from "sweetalert";
import "./contact.css";

const Contact = () => {
  const [sending, setSending] = useState(false);
  const [name, setnName] = useState("");
  const [error, setError] = useState("");
  const handleName = (e) => {
    const name = e.target.value;
    setnName(name);
  };
  const handleSubmit = (e) => {
    setSending(true);
    e.preventDefault();

    emailjs
      .sendForm(
        "service_jzq9j1m",
        "template_629zilk",
        e.target,
        "user_n55Xa3OsL5mZ01VUyqjtg"
      )
      .then(
        (result) => {
          swal(
            `Thank you ${name}!`,
            "I just received your message. I will get in touch as soon as possible. If you have any urgency, please message me on WhatsApp +8801760265926",
            "success"
          );
          setSending(false);
          e.target.reset();
          setError("");
        },
        (error) => {
          setError(error.text);
          setSending(false);
        }
      );
  };
  return (
    <section className="contact primary-bg primary-color">
      <div className="page-container">
        <h2 className="page-title">Contact</h2>
        <div className="contact-body">
          <div className="address-boxes">
            <div className="address-box">
              <i className="lnr lnr-map-marker"></i>
              <h4>Dhaka, Bangladesh</h4>
            </div>
            <div className="address-box">
              <i className="lnr lnr-phone-handset"></i>
              <h4>+880 1760265926</h4>
            </div>
            <div className="address-box">
              <i className="lnr lnr-envelope"></i>
              <h4>marufmarzuq18@gmail.com</h4>
            </div>
            <div className="address-box">
              <i className="lnr lnr-checkmark-circle"></i>
              <h4>Freelance Available</h4>
            </div>
          </div>
          <div className="contact-form">
            <h3 className="sub-title">
              How Can I <span>Help You?</span>
            </h3>
            <form onSubmit={handleSubmit}>
              <input
                onBlur={handleName}
                className="in-style"
                type="text"
                placeholder="Full Name"
                name="user_name"
                required
              />
              <input
                className="in-style"
                type="email"
                placeholder="Email Address"
                name="user_email"
                required
              />
              <input
                className="in-style"
                type="text"
                placeholder="Subject"
                name="user_subject"
                required
              />
              <textarea
                className="in-style"
                name="message"
                cols="30"
                rows="10"
                placeholder="Write your messages here ..."
                required
              ></textarea>
              {error && <p style={{ color: "red" }}>Error: {error}</p>}
              <input
                className="contact-btn"
                type="submit"
                value={sending ? "Sending.." : "Send"}
              />
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
